<template>
  <b-overlay
      rounded="sm"
      :show="!tableData"
  >
    <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >

      <template #cell(id)="data">
        <b-link
            :to="{ name: 'app-order-detail', params: { id: data.item.id }}"
            class="font-weight-bold"
        >
          #{{ data.item.id }}
        </b-link>
      </template>

      <template #cell(user)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.user.company.name }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.user.fullname }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(policyHolderRelation)="data">
        <div class="d-flex align-items-center">
          <div>
            <div>
              {{ getFullName(data.item.policyHolderRelation) }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.policyHolderRelation.birthDate }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(product)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.product.name }}</span>
        </div>
      </template>

      <template #cell(timestamp)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{formatDateTime(data.value)}}</span>
        </div>
      </template>

      <template #cell(paymentMethod)="data">
        <div class="d-flex align-items-center">
          <div>
            <div>
              {{ data.item.paymentMethod }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.paymentFrequency }}
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BLink, BOverlay, BTable,
} from 'bootstrap-vue'
import { formatDateTime } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BTable,
    BOverlay,
    BLink,
  },
  props: {
    tableData: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'user', label: 'Organisatie' },
        { key: 'policyHolderRelation', label: 'Verzekernemer' },
        { key: 'product', label: 'Product' },
        { key: 'paymentMethod', label: 'Betaalwijze' },
        { key: 'timestamp', label: 'Aangemaakt op' },
      ],
    }
  },
  methods: {
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
  },
  setup() {
    return {
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include.scss';
@import '~@core/scss/base/components/_variables-dark.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
