<template>
  <b-overlay
      rounded="sm"
      :show="!data"
  >
    <b-card
    no-body
    class="card-statistics"
    style="min-height:260px"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Orders en offertes verdeeld per status
      </b-card-text>
    </b-card-header>
    <b-card-body
        class="statistics-body"
        v-if="data"
    >
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.type"
          xl="3"
          sm="6"
          :class="`${item.customClass} mb-2`"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.type"
              >
                <feather-icon
                  size="24"
                  :icon="resolveType(item.type).icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ resolveType(item.type).name }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
  },
  props: {
    data: {
      type: Array,
      default: () => null,
    },
  },
  methods: {
    resolveType(type) {
      if (type === 'light-accept-offer') {
        return { icon: 'ClipboardIcon', color: '#000000', name: 'Offerte' }
      }

      if (type === 'light-medical-check') {
        return { icon: 'ActivityIcon', color: '#000000', name: 'Medisch' }
      }

      if (type === 'light-accept-order') {
        return { icon: 'ShoppingCartIcon', color: '#000000', name: 'Order' }
      }

      if (type === 'light-active-order') {
        return { icon: 'AwardIcon', color: '#000000', name: 'Actief' }
      }

      if (type === 'light-offer-declined') {
        return { icon: 'XOctagonIcon', color: '#000000', name: 'Afgewezen' }
      }

      if (type === 'light-offer-expired') {
        return { icon: 'ClockIcon', color: '#000000', name: 'Verlopen' }
      }

      if (type === 'light-prolongatie') {
        return { icon: 'SendIcon', color: '#000000', name: 'Prolongatie' }
      }

      if (type === 'light-order-terminated') {
        return { icon: 'PauseIcon', color: '#000000', name: 'Beëindigd' }
      }

      return { icon: '', color: '' }
    },
  },
}
</script>
<style>
[dir] .b-avatar.badge-light-accept-offer {
  background-color: rgba(166, 164, 176,.12);
}

.b-avatar.badge-light-accept-offer {
  color: rgb(166, 164, 176);
}

[dir] .b-avatar.badge-light-medical-check, [dir] .b-avatar.badge-light-offer-declined, [dir] .b-avatar.badge-light-order-terminated {
  background-color: rgba(234,84,85,.12);
}

.b-avatar.badge-light-medical-check, .b-avatar.badge-light-offer-declined, .b-avatar.badge-light-order-terminated {
  color: rgb(234,84,85);
}

[dir] .b-avatar.badge-light-accept-order {
  background-color: rgba(253, 184, 19,.12);
}

.b-avatar.badge-light-accept-order {
  color: rgb(253, 184, 19);
}

[dir] .b-avatar.badge-light-active-order {
  background-color: rgba(40,199,111,.12);
}

.b-avatar.badge-light-active-order {
  color: rgb(40,199,111);
}

[dir] .b-avatar.badge-light-offer-expired{
  background-color: rgba(255, 159, 67,.12);
}

.b-avatar.badge-light-offer-expired {
  color: rgb(255, 159, 67);
}

[dir] .b-avatar.badge-light-prolongatie{
  background-color: rgba(0,207,232,.12);
}

.b-avatar.badge-light-prolongatie {
  color: rgb(0,207,232);
}

</style>
