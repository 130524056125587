<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        cols="12"
        md="3"
      >
        <order-chart :data="data.statisticsActiveOrders" />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <offer-chart :data="data.statisticsOffers" />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <status-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col
        v-if="data.transactionTable"
        cols="12"
      >
        <transaction-table :table-data="data.transactionTable" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="4"
      >
        <status-donut-chart :data="data.productDonutChart" />
      </b-col>
      <b-col
        v-if="data.statisticsStatus"
        cols="12"
        md="8"
      >
        <echart-bar :data="data.statisticsStatus" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <company-table :table-data="data.companyTable" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dashboardStoreModule from '@/views/pages/dashboard/dashboardStoreModule'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import StatusStatistics from '@/views/pages/dashboard/dashboard-component/StatusStatistics.vue'
import StatusDonutChart from '@/views/pages/dashboard/dashboard-component/StatusDonutChart.vue'
import TransactionTable from '@/views/pages/dashboard/dashboard-component/TransactionTable.vue'
import OrderChart from './dashboard-component/OrderChart.vue'
import CompanyTable from './dashboard-component/CompanyTable.vue'
import EchartBar from './dashboard-component/EchartBar.vue'
import OfferChart from './dashboard-component/OfferChart.vue'

export default {
  components: {
    BRow,
    BCol,

    EchartBar,
    StatusStatistics,
    OrderChart,
    OfferChart,
    CompanyTable,
    StatusDonutChart,
    TransactionTable,
  },
  data() {
    return {
      data: {
        statisticsActiveOrders: null,
        statisticsOffers: null,
        statisticsItems: null,
        companyTable: null,
        transactionTable: null,
        statisticsStatus: null,
        productDonutChart: null,
      },
    }
  },
  created() {
    store.dispatch('app-dashboard/fetchDashboard', { id: router.currentRoute.params.id })
      .then(response => {
        response.data.statisticsActiveOrders.series.name = 'Orders'
        response.data.statisticsOffers.series.name = 'Offertes'

        response.data.statisticsStatus = {
          xAxis: [
            {
              type: 'category',
              data: response.data.statisticsStatus.days,
            },
          ],
          yAxis: [
            {
              type: 'value',
              splitLine: { show: false },
            },
          ],
          grid: {
            left: '40px',
            right: '30px',
            bottom: '30px',
          },
          series: [
            {
              name: 'Offertes',
              type: 'bar',
              stack: 'advertising',
              data: response.data.statisticsStatus.offer,
              barMaxWidth: '20%',
              barMinWidth: '10px',
            },
            {
              name: 'Inbehandeling',
              type: 'bar',
              stack: 'advertising',
              data: response.data.statisticsStatus.actionRequired,
              barMaxWidth: '20%',
              barMinWidth: '10px',
            },
            {
              name: 'Actief',
              type: 'bar',
              stack: 'advertising',
              data: response.data.statisticsStatus.active,
              barMaxWidth: '20%',
              barMinWidth: '10px',
            },
            {
              name: 'Afgewezen of verlopen',
              type: 'bar',
              stack: 'advertising',
              data: response.data.statisticsStatus.declined,
              barMaxWidth: '20%',
              barMinWidth: '10px',
              itemStyle: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          ],
        }

        response.data.productDonutChart = {
          series: response.data.productDonutChart.series,
          chartOptions: {
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: 'Montserrat',
            },
            colors: [
              '#87a7b3',
              '#ca8a8b',
              '#7b6079',
              '#557571',
            ],
            dataLabels: {
              enabled: true,
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}%`
              },
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1rem',
                      fontFamily: 'Montserrat',
                    },
                    value: {
                      fontSize: '1rem',
                      fontFamily: 'Montserrat',
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}`
                      },
                    },
                    total: {
                      show: false,
                      fontSize: '1.5rem',
                      label: 'Producten',
                      formatter() {
                        return '31%'
                      },
                    },
                  },
                },
              },
            },
            labels: response.data.productDonutChart.labels,
            responsive: [
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 380,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
              {
                breakpoint: 576,
                options: {
                  chart: {
                    height: 320,
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          name: {
                            fontSize: '1rem',
                          },
                          value: {
                            fontSize: '1rem',
                          },
                          total: {
                            fontSize: '1.5rem',
                          },
                        },
                      },
                    },
                  },
                  legend: {
                    show: true,
                  },
                },
              },
            ],
          },
        }

        this.data = response.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ophalen statistieken',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorDefaultMessage.fetchDataError}`,
          },
        })
      })
  },
  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.echarts{
  width: 100% !important;
}
</style>
