<template>
  <b-overlay
      rounded="sm"
      :show="!tableData"
  >
    <b-card
    no-body
    class="card-company-table"
    style="min-height:260px"
  >
    <b-table
      v-if="tableData"
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(company)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.company }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.type }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(amountOffers)="data">
        <div class="d-flex align-items-center">
            <feather-icon
                size="18"
                icon="ClipboardIcon"
                class="mr-1"
            />
          <span>{{ data.item.amountOffers }}</span>
        </div>
      </template>

      <template #cell(amountCancels)="data">
        <div class="d-flex align-items-center">
            <feather-icon
                size="18"
                icon="XOctagonIcon"
                class="mr-1"
            />
          <span>{{ data.item.amountCancels }}</span>
        </div>
      </template>

      <template #cell(amountOrders)="data">
        <div class="d-flex align-items-center">
            <feather-icon
                size="18"
                icon="AwardIcon"
                class="mr-1"
            />
          <span>{{ data.item.amountOrders }}</span>
        </div>
      </template>

      <!-- revenue -->
      <template #cell(revenue)="data">
        {{ parseFloat(data.item.revenue).toLocaleString("nl-NL") }}
      </template>
    </b-table>
  </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BOverlay, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BOverlay,
  },
  props: {
    tableData: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      fields: [
        { key: 'company', label: 'Organisatie' },
        { key: 'amountCancels', label: 'Afgewezen' },
        { key: 'amountOffers', label: 'Offertes' },
        { key: 'amountOrders', label: 'Orders' },
        { key: 'revenue', label: 'Omzet' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include.scss';
@import '~@core/scss/base/components/_variables-dark.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
