<template>
  <b-overlay
    rounded="sm"
    :show="!data"
  >
    <b-card
      style="min-height:260px"
    >
      <b-card-title class="mb-1">
        Producten
      </b-card-title>
      <b-card-sub-title class="mb-2">
        Aantal orders per product
      </b-card-sub-title>

      <vue-apex-charts
        v-if="data"
        type="donut"
        height="500"
        :options="data.chartOptions"
        :series="data.series"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
}
</script>
<style>
.apexcharts-legend.position-bottom.apexcharts-align-center, .apexcharts-legend.position-top.apexcharts-align-center {
  justify-content: left !important;
}
</style>
